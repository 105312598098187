<template>
  <div>
    <v-container fluid>
      <v-card-title>
        유저 그룹 수정하기
      </v-card-title>
      <v-card outlined>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><h4>유저 그룹 이름</h4></v-list-item-title>
              <v-list-item-content>
                <v-text-field v-model="req.name" class="mr-3" placeholder="그룹 이름을 작성해주세요." outlined hide-details dense />
              </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><h4>그룹 설명</h4></v-list-item-title>
              <v-list-item-content>
                <v-text-field v-model="req.detail" class="mr-3" placeholder="그룹 설명을 작성해주세요." outlined hide-details dense />
              </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4>조건 선택</h4>
                <div style="margin: -16px 80px 0px; font-size: 13px">
                  <div>
                    유저 그룹을 만들 조건을 만들어주세요.
                  </div>
                  <div>
                    추가하고 싶은 조건을 선택하여 조건추가 버튼을 누르면 조건을 추가할 수 있습니다.
                  </div>
                  <div>
                    복수 선택 가능하며, 각 조건은 AND 조건이 적용됩니다.
                  </div>
                </div>
              </v-list-item-title>
              <v-list-item-content>
                <v-card outlined class="pa-5">
                  <v-row>
                    <v-col cols="3">
                      <v-btn-toggle
                        v-model="base.first"
                        color="deep-purple accent-3"
                        borderless
                        tile
                        class="d-flex flex-column"
                        @change="changeDepth(0, base.first)"
                      >
                        <v-btn v-for="(item, name) in base.ifList" :key="name" :value="name">
                          {{ name }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-divider v-if="base.first" vertical />
                    <v-col v-if="base.first === '답변평균점수'">
                      <v-rating
                        v-model="base.temp"
                        clearable
                        empty-icon="mdi-star-outline"
                        full-icon="mdi-star"
                        size="32"
                        color="deep-purple accent-1"
                        background-color="grey accent-1"
                      />
                    </v-col>
                    <v-col v-else>
                      <v-btn-toggle
                        v-model="base.second"
                        color="deep-purple accent-3"
                        borderless
                        tile
                        class="d-flex flex-column"
                        @change="changeDepth(1, base.first, base.second)"
                      >
                        <v-btn v-for="(item, name) in base.secondList" :key="name" :value="Array.isArray(base.ifList[base.first]) ? item : name">
                          {{ Array.isArray(base.ifList[base.first]) ? item : name }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-divider v-if="base.second && base.ifList[base.first][base.second]" vertical />
                    <v-col>
                      <v-btn-toggle
                        v-model="base.third"
                        color="deep-purple accent-3"
                        borderless
                        tile
                        class="d-flex flex-column"
                        @change="changeDepth(2, base.first, base.second, base.third)"
                      >
                        <v-btn v-for="(item, name) in base.thirdList" :key="name" :value="Array.isArray(base.ifList[base.first][base.second]) ? item : name">
                          {{ Array.isArray(base.ifList[base.first][base.second]) ? item : name }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-divider v-if="base.third && base.ifList[base.first][base.second][base.third]" vertical />
                    <v-col>
                      <v-btn-toggle v-model="base.fourth" color="deep-purple accent-3" borderless tile class="d-flex flex-column">
                        <v-btn v-for="name in base.fourthList" :key="name" :value="name">
                          {{ name }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn class="mx-auto my-3" color="deep-purple accent-1 font-weight-bold" depressed dark large @click="addIf">
                  조건 추가
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-card outlined class="pa-5">
                  <div v-for="(list, index) in ifList" :key="index" class="mt-3">
                    {{ index + 1 }}.
                    <span v-for="(item, i) in list" :key="i">{{ i > 0 && item ? '- ' : '' }} {{ item }}</span>
                    <v-btn light class="ml-3 mb-1" rounded small color="#ffd600" @click="ifList.splice(index, 1)" v-text="'삭제'" />
                  </div>
                </v-card>
              </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                유저 선택
                <div style="margin: -16px 80px 0px; font-size: 13px">
                  <div>
                    유저 그룹을 만들 조건을 만들어주세요.
                  </div>
                  <div>
                    추가하고 싶은 조건을 선택하여 조건추가 버튼을 누르면 조건을 추가할 수 있습니다.
                  </div>
                  <div>
                    복수 선택 가능하며, 각 조건은 AND 조건이 적용됩니다.
                  </div>
                </div>
              </v-list-item-title>
              <v-list-item-content>
                <v-combobox
                  v-model="userName"
                  class="mr-3"
                  dense
                  hide-details
                  outlined
                  :items="userNames"
                  placeholder="유저 이름을 검색해주세요."
                  @keyup.enter="findName"
                />
                <v-btn color="#ffd600" light depressed @click="addUserList">
                  추가
                </v-btn>
                <div v-for="(item, index) in req.userList" :key="index" class="mt-3">
                  {{ index + 1 }}. {{ item.text }}
                  <v-btn light class="ml-3 mb-1" rounded small color="#ffd600" @click="req.userList.splice(index, 1)" v-text="'삭제'" />
                </div>
              </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn color="#9146ff" class="mb-3" dark @click="push(req)">
            수정하기
          </v-btn>
        </v-card-actions>
        <v-snackbar v-model="base.snackbar" timeout="2500">
          항목을 선택하거나 정확히 입력해주세요.
          <template v-slot:action="{ attrs }">
            <v-btn icon v-bind="attrs" @click="base.snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'

const msAndHsGrade = ['전체', '1학년', '2학년', '3학년']
const esGrade = [...msAndHsGrade, '4학년', '5학년', '6학년']

export default defineComponent({
  props: {
    type: String,
    idOfType: String,
  },

  setup(props, { root }) {
    const state = reactive({
      base: {
        snackbar: false,
        temp: null,
        first: null,
        second: null,
        third: null,
        fourth: null,
        ifList: {
          학력: {
            초등학교: esGrade,
            중학교: {
              전체: msAndHsGrade,
              일반: msAndHsGrade,
              국제: msAndHsGrade,
              예술: msAndHsGrade,
              체육: msAndHsGrade,
              대안: msAndHsGrade,
              기타: msAndHsGrade,
              NUL: msAndHsGrade,
            },
            고등학교: {
              전체: msAndHsGrade,
              일반: msAndHsGrade,
              자율: msAndHsGrade,
              특목: msAndHsGrade,
              특성화: msAndHsGrade,
              전문: msAndHsGrade,
              과학: msAndHsGrade,
              국제: msAndHsGrade,
              예술: msAndHsGrade,
              외국어: msAndHsGrade,
              체육: msAndHsGrade,
              대안: msAndHsGrade,
              기타: msAndHsGrade,
              NUL: msAndHsGrade,
            },
            N수: null,
            학부모: null,
            검정고시: null,
            멘토: null,
            기타: null,
          },
          질답횟수: {
            '0번': null,
            '1번': null,
            '2번': null,
            '3번 이상': null,
          },
          답변평균점수: null,
          최종접속일: {
            '0~24시간': null,
            '24~48시간': null,
            '48~72시간': null,
            '72시간 이후': null,
          },
          성별: {
            남성: null,
            여성: null,
            기타: null,
          },
        },
      },
      req: {
        name: null,
        detail: null,
        userList: [],
      },
      ifList: [],
      group: null,
      groups: [],
      introChat: null,
      conclusionChat: null,
      userNames: [],
      userName: null,
    })

    onMounted(async () => {
      const group = await root.$store.dispatch('group/getOneGroup', {
        name: root.$route.params.name,
      })
      state.req.name = group.name
      state.req.detail = group.detail

      group.condition.userList.forEach((e) => {
        state.req.userList.push({ text: e, value: e })
        console.log(state.req.userList)
      })

      if (group.condition.receivers.schoolList)
        group.condition.receivers.schoolList.forEach((e) => {
          let second, third, fourth

          if (e.education === 'elementary') second = '초등학교'
          else if (e.education === 'middle') second = '중학교'
          else if (e.education === 'high') second = '고등학교'
          else if (e.education === 'nAgain' || e.education === 'halfAgain') second = 'N수'
          else if (e.education === 'parents') second = '학부모'
          else if (e.education === 'homeSchool') second = '검정고시'
          else if (e.education === 'mentor') second = '멘토'
          else if (e.education === 'other') second = '기타'

          if (e.kindList) {
            if (e.kindList[0] === 'general') third = '일반'
            else if (e.kindList[0] === 'autonomous') third = '자율'
            else if (e.kindList[0] === 'specialPurpose') third = '특목'
            else if (e.kindList[0] === 'specialized') third = '특성화'
            else if (e.kindList[0] === 'technical') third = '전문'
            else if (e.kindList[0] === 'science') third = '과학'
            else if (e.kindList[0] === 'international') third = '국제'
            else if (e.kindList[0] === 'art') third = '예술'
            else if (e.kindList[0] === 'foreign') third = '외국어'
            else if (e.kindList[0] === 'physical') third = `체육`
            else if (e.kindList[0] === 'alternative') third = `대안`
            else if (e.kindList[0] === 'etc') third = `기타`
            else if (e.kindList[0] === 'empty' || e.kindList[0] === 'nul') third = 'NUL'
          } else if (e.education === 'middle' || e.education === 'high') third = '전체'

          if (e.yearList && e.yearList[0] && third) fourth = `${e.yearList[0]} 학년`
          else if (e.yearList && e.yearList[0]) third = `${e.yearList[0]} 학년`
          else if (e.education === 'elementary' || e.education === 'middle' || e.education === 'high') fourth = '전체'

          state.ifList.push(['학력', second, third, fourth])
        })
      if (group.condition.receivers.questionCountList)
        group.condition.receivers.questionCountList.forEach((second) => {
          if (second > 2) state.ifList.push(['질답횟수', '3번 이상', null, null])
          else state.ifList.push(['질답횟수', second, null, null])
        })
      if (group.condition.receivers.likeAvgList)
        group.condition.receivers.likeAvgList.forEach((second) => {
          state.ifList.push(['답변평균점수', second, null, null])
        })
      if (group.condition.receivers.afterBeforeTimeList)
        group.condition.receivers.afterBeforeTimeList.forEach((e) => {
          let second
          if (e[1] === 24) second = '0~24시간'
          else if (e[1] === 48) second = '24~48시간'
          else if (e[1] === 72) second = '48~72시간'
          else if (e[1] === 0) second = '72시간 이후'
          state.ifList.push(['최종접속일', second, null, null])
        })
      if (group.condition.receivers.sexList)
        group.condition.receivers.sexList.forEach((e) => {
          let first
          if (e === 'male') first = '남성'
          else if (e === 'female') first = '여성'
          else if (e === 'none') first = '기타'
          state.ifList.push(['성별', first, null, null])
        })
    })

    const addIf = () => {
      if (!state.base.first) return (state.base.snackbar = true)
      else if (state.base.secondList && !state.base.second) return (state.base.snackbar = true)
      else if (state.base.thirdList && !state.base.third) return (state.base.snackbar = true)
      else if (state.base.fourthList && !state.base.fourth) return (state.base.snackbar = true)
      if (state.base.first === '답변평균점수') {
        state.ifList.push([state.base.first, state.base.temp ? state.base.temp : '평가 없음', state.base.third, state.base.fourth])
      } else if (state.base.second === '재수' || state.base.second === '반수') {
        if (!state.base.temp || state.base.temp < 0) return (state.base.snackbar = true)
        state.ifList.push([state.base.first, state.base.second, state.base.temp, state.base.fourth])
      } else state.ifList.push([state.base.first, state.base.second, state.base.third, state.base.fourth])
      state.base.all = false
    }

    const changeDepth = (number, first, second, third) => {
      state.base.fourth = null
      state.base.fourthList = null
      state.base.temp = null
      if (number === 2) {
        if (!Array.isArray(state.base.ifList[first][second])) state.base.fourthList = state.base.ifList[first][second][third]
      } else if (number === 1) {
        state.base.third = null
        if (!Array.isArray(state.base.ifList[first])) state.base.thirdList = state.base.ifList[first][second]
      } else if (number === 0) {
        state.base.third = null
        state.base.second = null
        state.base.thirdList = null
        if (!Array.isArray(state.base.ifList)) state.base.secondList = state.base.ifList[first]
      }
    }

    const findName = async (e) => {
      if (!e.target.value) return
      try {
        const newName = await root.$store.dispatch('app/getListUser', {
          name: e.target.value,
        })

        state.userNames = newName.map((user) => {
          return {
            text: `${user.name} (${user.id})`,
            value: parseInt(user.id),
          }
        })
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const push = async (req) => {
      try {
        const data = {
          name: root.$store.state.group,
          ...req,
          userList: req.userList.map((user) => Number(user.value)),
          receivers: {},
        }

        const res = data.receivers
        for (const index in state.ifList) {
          const e = state.ifList[index]
          if (e[0] === '학력') {
            if (!res.schoolList) res.schoolList = []
            if (e[1] === '초등학교') res.schoolList.push({ education: 'elementary' })
            else if (e[1] === '중학교') res.schoolList.push({ education: 'middle' })
            else if (e[1] === '고등학교') res.schoolList.push({ education: 'high' })
            else if (e[1] === 'N수') res.schoolList.push({ education: 'nAgain' }, { education: 'halfAgain' })
            else if (e[1] === '학부모') res.schoolList.push({ education: 'parents' })
            else if (e[1] === '검정고시') res.schoolList.push({ education: 'homeSchool' })
            else if (e[1] === '멘토') res.schoolList.push({ education: 'mentor' })
            else if (e[1] === '기타') res.schoolList.push({ education: 'other' })
            const lastSchoolList = res.schoolList[res.schoolList.length - 1]
            if (e[2] && e[2].includes('학년')) lastSchoolList.yearList = [Number(e[2][0])]
            else if (e[2] === '일반') lastSchoolList.kindList = ['general']
            else if (e[2] === '자율') lastSchoolList.kindList = ['autonomous']
            else if (e[2] === '특목') lastSchoolList.kindList = ['specialPurpose']
            else if (e[2] === '특성화') lastSchoolList.kindList = ['specialized']
            else if (e[2] === '전문') lastSchoolList.kindList = ['technical']
            else if (e[2] === '과학') lastSchoolList.kindList = ['science']
            else if (e[2] === '국제') lastSchoolList.kindList = ['international']
            else if (e[2] === '예술') lastSchoolList.kindList = ['art']
            else if (e[2] === '외국어') lastSchoolList.kindList = ['foreign']
            else if (e[2] === '체육') lastSchoolList.kindList = ['physical']
            else if (e[2] === '대안') lastSchoolList.kindList = ['alternative']
            else if (e[2] === '기타') lastSchoolList.kindList = ['etc']
            else if (e[2] === 'NUL') lastSchoolList.kindList = ['empty', 'nul']
            if (e[3] && e[3].includes('학년')) lastSchoolList.yearList = [Number(e[3][0])]
          } else if (e[0] === '질답횟수') {
            if (!res.questionCountList) res.questionCountList = []
            res.questionCountList.push(Number(e[1][0]))
          } else if (e[0] === '답변평균점수') {
            if (!res.likeAvgList) res.likeAvgList = []
            res.likeAvgList.push(Number(e[1]))
          } else if (e[0] === '최종접속일') {
            if (!res.afterBeforeTimeList) res.afterBeforeTimeList = []
            if (e[1] === '0~24시간') res.afterBeforeTimeList.push([0, 24])
            else if (e[1] === '24~48시간') res.afterBeforeTimeList.push([24, 48])
            else if (e[1] === '48~72시간') res.afterBeforeTimeList.push([48, 72])
            else if (e[1] === '72시간 이후') res.afterBeforeTimeList.push([72, 0])
          } else if (e[0] === '성별') {
            if (!res.sexList) res.sexList = []
            if (e[1] === '남성') res.sexList.push('male')
            else if (e[1] === '여성') res.sexList.push('female')
            else if (e[1] === '기타') res.sexList.push('none')
          }
        }

        await root.$store.dispatch('group/updateGroup', data)
        alert('생성 완료')
        root.$router.push('/group')
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const addUserList = () => {
      if (!state.userName) return (state.base.snackbar = true)
      if (!state.req.userList.find((e) => e === state.userName)) state.req.userList.push(state.userName)
      state.base.all = false
      state.userName = null
    }

    return {
      ...toRefs(state),
      addIf,
      changeDepth,
      findName,
      push,
      addUserList,
    }
  },
})
</script>
